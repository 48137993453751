import React from "react";
import Button from "./Universal/Button";

const Navbar = () => {
  return (
    <div className="flex justify-between">
      <div>
        <img src="/assets/Logo.png" alt="Logo: Pc service" className="w-80" />
      </div>
      <div className="hidden sm:block">
        <Button text="Kontakt oss" type="secondary" />
      </div>
    </div>
  );
};

export default Navbar;
