import React from "react";
import { reviews } from "../CONSTANTS/constants";

const Reviews = () => {
  return (
    <div className="py-24">
      <div className="flex flex-col gap-y-4 text-center items-center">
        <h3 className="text-primary text-xl">ANMELDELSER</h3>
        <h2 className="text-white text-3xl sm:text-4xl font-pixeled mb-4 leading-[2.5] sm:leading-loose">
          HVA VÅRE KUNDER SIER
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full mb-4">
          {reviews.map((review) => (
            <div
              key={review.id}
              className="border-image-button-gray p-8 flex flex-col text-left gap-y-4"
            >
              <div className="flex flex-row gap-6">
                <img
                  src={review.image}
                  alt={review.name}
                  className="h-16 rounded-full border-primary border-2"
                />
                <p className="text-2xl text-primary my-auto">{review.name}</p>
              </div>
              <p className="mb-8">{review.review}</p>
              <p className="text-muted">{review.dato}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
