import React from "react";
import Button from "./Universal/Button";

const Contact = () => {
  return (
    <div className="py-24">
      <div className="border-image-button-gray p-12 flex flex-col md:flex-row text-left gap-y-4">
        <div className="w-full md:w-1/2 flex flex-col gap-y-6">
          <h2 className="text-1xl font-pixeled">KONTAKT OSS</h2>
          <p>
            Send oss en e-post om hva du ønsker så kommer vi tilbake til deg så
            fort som mulig.
          </p>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <label htmlFor="navn">Navn</label>
              <input
                id="navn"
                type="text"
                placeholder="Ola Nordmann"
                className="px-4 py-2 text-black"
              ></input>
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="e-post">E-post</label>
              <input
                id="e-post"
                type="email"
                placeholder="ola.nordmann@gmail.com"
                className="px-4 py-2 text-black"
              ></input>
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="tjeneste">Tjeneste</label>
              <select id="tjeneste" className="text-black px-4 py-2">
                <option value="Skreddersydd PC" selected>
                  Skreddersydd PC
                </option>
                <option value="PC reparasjon">PC Reparasjon</option>
                <option value="Kjøp PC">Kjøp PC</option>
              </select>
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="melding">Melding</label>
              <textarea
                id="melding"
                placeholder="Skriv meldingen din her..."
                className=" text-black px-4 py-2"
                rows="3"
              ></textarea>
            </div>
            <Button
              text="Send melding"
              type="secondary"
              classNames="text-white w-full font-bold text-xl"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 pl-8 h-full my-auto">
          <img
            src="https://pc-service.no/assets/contact.png"
            alt="Kontakt"
            className="border-image-button-gray p-3 w-full max-w-[30rem] mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
