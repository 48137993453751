import React from "react";
import Button from "./Universal/Button";
import { benefits } from "../CONSTANTS/constants";

const Benefits = () => {
  return (
    <div className="py-24">
      <div className="flex flex-col gap-y-4 text-center items-center">
        <h3 className="text-primary text-xl">FORDELER</h3>
        <h2 className="text-white text-3xl sm:text-4xl font-pixeled mb-4 leading-[2.5] sm:leading-loose">
          HVORFOR MELDE SEG PÅ?
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full mb-4">
          {benefits.map((benefit) => (
            <div
              key={benefit.id}
              className="border-image-button-gray p-8 flex flex-col text-left gap-y-4"
            >
              <div className="p-4 bg-primary w-fit rounded-full text-black">
                <benefit.icon />
              </div>
              <h4 className="text-lg font-semibold">{benefit.title}</h4>
              <p className="text-white">{benefit.description}</p>
            </div>
          ))}
        </div>
        <Button text="Abonner og hold deg oppdatert" type="primary" />
      </div>
    </div>
  );
};

export default Benefits;
