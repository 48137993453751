import React from "react";
import Button from "./Universal/Button";

const Footer = () => {
  return (
    <div className="w-full py-16 bg-footer text-center">
      <div className="container mx-auto flex flex-col gap-4 md:flex-row px-4">
        <div className="w-full md:w-1/2 flex flex-col gap-y-4">
          <h2 className="text-2xl font-pixeled leading-[3rem]">
            LIGG I FORKANT - BLI MED I VÅRT PC FELLESSKAP!
          </h2>
          <p>
            Abonner nå for eksperttips, ekslusive tilbud og PC råd rett i
            innboksen din.
          </p>
        </div>
        <div className="w-full md:w-1/2 flex flex-col gap-y-4">
          <input
            type="email"
            placeholder="ola.nordmann@gmail.com"
            className="text-center px-4 py-4 text-black"
          />
          <Button
            type="secondary"
            text="Abonner nå"
            classNames="w-full text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
