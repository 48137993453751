import About from "./components/About";
import Benefits from "./components/Benefits";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Reviews from "./components/Reviews";
import Services from "./components/Services";
import Slider from "./components/Slider";

function App() {
  return (
    <div className="min-h-screen w-full bg-dark text-white">
      <div className="container mx-auto p-6 sm:p-12">
        <Navbar />
        <Hero />
      </div>
      <Slider />
      <div className="container mx-auto p-6 sm:p-12">
        <Benefits />
        <Services />
        <About />
        <Reviews />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
